<!--
 * @Author: your name
 * @Date: 2020-11-10 12:54:12
 * @LastEditTime: 2021-01-08 14:57:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /NewCunwUaregoodWebSite/src/App.vue
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// 375：x/100/2 移动端适配单位换算   font-size设置100px
// (function(doc, win) {
//   var docEl = doc.documentElement;
//   var resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
//   var recalc = function() {
//     var clientWidth = docEl.clientWidth;
//     if (!clientWidth) return;
//     if (clientWidth >= 750) {
//       docEl.style.fontSize = '100px';
//     } else {
//       docEl.style.fontSize = 100 * (clientWidth / 750) + 'px';
//     }
//   };
//   if (!doc.addEventListener) return;
//   win.addEventListener(resizeEvt, recalc, false); // 绑定事件
//   doc.addEventListener('DOMContentLoaded', recalc, false);
// })(document, window);
export default {
  name: 'App',
  beforeCreate() {
    // 存储token，转化为个人信息
    // 1.存在：next()
    // 2.不存在：有token；直接转存；无token，跳转门户登录
    /* const localStorageDataKeys = ['userCity', 'citys']

   localStorageDataKeys.forEach(item => {
      const temp = localStorage.getItem('fgState_' + item)
      if (
        this.isNotEmpty(temp) &&
        this.isNotEmpty(JSON.parse(temp)) &&
        this.$30s.isObject(JSON.parse(temp))
      ) {
        this.$store.dispatch(
          'set' + item.substring(0, 1).toUpperCase() + item.substring(1),
          JSON.parse(temp)
        )
      }
      // 在页面刷新时将vuex里的信息保存到localStorage里
      window.addEventListener('beforeunload', () => {
        const temp = this.$store.getters[item]
        if (this.$30s.isObject(temp) && this.isNotEmpty(temp)) {
          localStorage.setItem(
            'fgState_' + item,
            JSON.stringify(this.$store.getters[item])
          )
        }
      })
    }) */
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1400px) {
  html{
    font-size:75px!important;
  }

}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  #app{
    background: #fff;
  }
}

</style>
