<!--
 * @Author: your name
 * @Date: 2020-11-06 18:15:12
 * @LastEditTime: 2020-12-03 17:14:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /NewCunwUaregoodWebSite/src/components/baseComon/TheHeaderOs.vue
-->
<template>
  <div class="nav-header" @touchmove.prevent>
    <div class="nav-mian">
      <nav class="header-box">
        <router-link to="/indexOs/osHome" class="logo">
          <img src="../../assets/images/os/logoOs.jpg">
        </router-link>
        <!-- pc -->
        <ul class="nav-list">
          <li>
            <router-link to="/indexOs/osHome">
              首页
            </router-link>
          </li>
          <li>
            <router-link to="/desk/home">
              课桌先生
            </router-link>
          </li>
          <li>
            <router-link to="/educ/educationPlatform">
              优智多趣味教学平台
            </router-link>
          </li>
          <li>
            <router-link to="/room/multiClassroom">
              交互式多功能教室
            </router-link>
          </li>
          <li>
            <a class="linkTo" href="http://www.uaregood.net" target="_blank">
              课桌先生官方商城
            </a>
          </li>
          <li>
            <router-link to="/indexOs/aboutUs">
              关于我们
            </router-link>
          </li>
          <!-- <li>
            <a class="linkTo" href="http://www.cunwedu.com.cn" target="_blank">
              优智多课堂
            </a>
          </li>
          <li>
            <a class="linkTo" href="http://www.uaregood.net" target="_blank">
              优智多商城
            </a>
          </li> -->
        </ul>
        <!-- mb -->
        <i :class="showNavStatus?'nav-icon-close':'nav-icon-open'" @click="openNav()" />
        <ul :class="showNavStatus?'nav-list-mb':'nav-list-mb-open'" @touchmove.prevent>
          <!-- <li>
            <router-link class="linkTo" to="/indexOs/osHome">
              首页
              <i class="arrow-r" />
            </router-link>
          </li>
          <li>
            <router-link to="/home" class="linkTo">
              课桌先生
              <i class="arrow-r" />
            </router-link>
          </li>
          <li>
            <a class="linkTo" href="http://www.cunwedu.com.cn" target="_blank">
              优智多课堂
              <i class="arrow-r" />
            </a>
          </li>
          <li>
            <a class="linkTo" href="http://www.uaregood.net" target="_blank">
              优智多商城
              <i class="arrow-r" />

            </a>
          </li>
          <li>
            <router-link class="linkTo" to="/indexOs/aboutUs">
              关于我们
              <i class="arrow-r" />
            </router-link>
          </li> -->
          <li v-for="(item,index) in navList" :key="index" @click="closeNav()">
            <router-link v-if="item.linkType==='router'" class="linkTo" :to="item.linkUrl">
              {{ item.title }}
              <i class="arrow-r" />
            </router-link>
            <a v-else :href="item.linkUrl" class="linkTo" target="_blank">
              {{ item.title }}
              <i class="arrow-r" />
            </a>
          </li>
          <li class="tel">
            <a class="tel-a" href="tel:4008701117">
              <i />
              <span>400-870-1117</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
// 菜单数据
export default {
  name: 'NavHeader',
  data() {
    return {
      showNavStatus: false,
      navList: [{
        title: '首页',
        linkUrl: '/indexOs/osHome',
        linkType: 'router'
      }, {
        title: '课桌先生',
        linkUrl: '/desk/home',
        linkType: 'router'
      }, {
        title: '优智多趣味教学平台',
        linkUrl: '/educ/educationPlatform',
        linkType: 'router'
      }, {
        title: '交互式多功能教室',
        linkUrl: '/room/multiClassroom',
        linkType: 'router'
      },
      // {
      //   title: '优智多课堂',
      //   linkUrl: 'http://www.cunwedu.com.cn',
      //   linkType: 'link'
      // },
      {
        title: '课桌先生官方商城',
        linkUrl: 'http://www.uaregood.net',
        linkType: 'link'
      }, {
        title: '关于我们',
        linkUrl: '/indexOs/aboutUs',
        linkType: 'router'
      }]
    };
  },
  methods: {
    openNav() {
      this.showNavStatus = !this.showNavStatus;
    },
    closeNav() {
      this.showNavStatus = false;
    }
  }

};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .nav-header{
    height:auto;
    width: 100%;
    background: #fff;
    // 一级导航菜单
    .nav-mian{
      height:100px;
      width: 100%;
      position: relative;
      box-shadow:0px 3px 7px rgba(215,194,177,0.5);
      z-index: 8;
      .header-box{
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #313131;
        .logo{
          img{
            width: 100%;
            height: auto;
          }
        }
        .logo-title{
          font-size: 24px;
          cursor: pointer;
        }
        .nav-list{
          display: flex;
          align-items: center;
          height: 100%;
          font-size: 16px;
          li{
            width: auto;
            text-align: center;
            height: 100%;
            display: flex;
            align-items: center;
            &:nth-of-type(1){
              margin-left: 0;
            }
            &:nth-of-type(6){
              a{
                border-right:none;
              }
            }
            a{
              height: 24px;
              font-size: 18px;
              width: auto;
              display: flex;
              align-items: center;
              border-right: 1px solid #535353;
              padding: 0 30px;
              &:hover{
                color: rgba(253, 156, 2, 1);
                font-weight: 600;
              }
            }
            .router-link-active{
              color: rgba(253, 156, 2, 1);
              font-weight: 600;
            }
          }
        }
        .nav-icon-open,.nav-icon-close,.nav-list-mb,.nav-list-mb-open{
          display: none;
        }
      }
    }

  }
}
@media screen and (max-width: 1200px) {
  .nav-header{
    height:auto;
    width: 100%;
    background: #fff;
    // 一级导航菜单
    .nav-mian{
      height:1.6rem;
      width: 100%;
      position: fixed;
      background: #fff;
      padding:.53rem ;
      box-shadow: 0px 3px 6px rgba(158, 79, 0, 0.16);
      z-index: 8;
      .header-box{
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #313131;
        position: relative;
        .logo{
          img{
            width: 3rem;
            height: auto;
          }
        }
        .logo-title{
          font-size: 24px;
          cursor: pointer;
        }
        .nav-list{
          display: none;
        }
        .nav-icon-open{
          display: inline-block;
          width:.48rem;
          height:.48rem;
          background: url('../../assets/images/moblie/nav-open.png') no-repeat 100%/100%;
        }
        .nav-icon-close{
          display: inline-block;
          width:.48rem;
          height:.48rem;
          background: url('../../assets/images/moblie/nav-close-yellow.png') no-repeat 100%/100%;
        }
        .nav-list-mb{
          position: fixed;
          left: 0;
          top:1.6rem;
          height: 100%;
          width: 100%;
          background: rgba(255, 255, 255, .9);
          padding:.53rem 1.44rem;
          li{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #D3CDC8;
            padding: .348rem 0 ;
            color: rgba(253, 156, 2, 1);
            font-size:.426rem ;
            .linkTo{
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            }
            .arrow-r{
              width: .213rem;
              height: .347rem;
              display: inline-block;
              background: url('../../assets/images/moblie/arrowR.png') no-repeat 100%/100%;
            }
          }
          .tel{
            display: flex;
            justify-content: flex-start;
            border: none;
            .tel-a{
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
            i{
              display: inline-block;
              width: .88rem;
              height: .88rem;
              background: url('../../assets/images/moblie/tel-yellow.png') no-repeat 100%/100%;
              margin-right: .187rem;
            }
            span{
              font-size: .506rem;
              line-height: 1.49rem;
              color: #FD9C02;
            }
          }
        }
        .nav-list-mb-open{
          display: none;
        }
      }
    }
  }
}
</style>
