/*
 * @Author: your name
 * @Date: 2020-05-19 17:06:46
 * @LastEditTime: 2020-12-31 11:19:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /NewCunwUaregoodWebSite/src/utils/request.js
 */
import axios from 'axios';
import ENVIRONMENT from './environment.js';
import qs from 'qs';
import { Message } from 'element-ui';
// axios 配置
// axios.defaults.baseURL = ENVIRONMENT.API_SCHOOL_URL;
// // 添加请求拦截器
// axios.interceptors.response.use((res) => {
//   return res.data;
// }, (err) => {
//   return Promise.reject(err);
// });

// export default axios;

// 创建axios实例
const service = axios.create({
  baseURL: ENVIRONMENT.API_SCHOOL_URL, // api 的 base_url
  timeout: 1000 * 60 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  config => {
    // if (config.url.search('/app/products') !== -1) {
    //   config.baseURL = '/Api';
    // }
    // 设置浏览器不缓存（除上传文件外）
    if (config.url.search('op/file/upload/temp2formal') === -1) {
      config.headers['Cache-Control'] = 'no-cache';
      config.headers['Pragma'] = 'no-cache';
    }
    // 设置请求数据格式
    config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json;charset=UTF-8';
    if (config.method === 'post') {
      // 如果为post请求，要求Content-Type为json时，数据需要转换成JSON字符串
      if (config.headers['Content-Type'] === 'application/json;charset=UTF-8') {
        config.data = JSON.stringify(config.data);
      } else {
        // 如果是FormData参数据不需要qs序列化处理，否则默认Content-Type数据格式都需要qs序列化处理
        if (!(config.data instanceof FormData)) {
          config.data = qs.stringify(config.data);
        }
      }
    }
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.code !== '0') {
      if (response.config.params && response.config.params.export && res && !res.failed) {
        // 如果是导出，response.data返回为乱码则不判断
        return response.data;
      }
      // 如果需要自定义对错误做处理
      if (response.config.data && response.config.data.indexOf('custom') > -1) {
        return response.data;
      }
      if (res.type === 'text/xml') { return Promise.reject(response); }// 如果是excel导出就不提示错误
      // 统一处理错误信息
      if (response.config.url.indexOf('/resource-activation/manual-activation') >= 0) {
        return Promise.reject(response);
      }
      res.message && Message({
        message: res.message,
        type: 'error',
        duration: 3 * 1000
      });
      return Promise.reject(response);
    } else {
      return response.data;
    }
  },
  error => {
    console.log('err' + error); // for debug
    if (error.message.includes('timeout')) { // 判断请求异常信息中是否含有超时timeout字符串
      Message({
        message: '请求超时',
        type: 'error'
      });
    } else if (error.response) {
      switch (error.response.status) {
        case 200:

          break;
        case 401:
          break;
        case 403:
        case 404:
        case 500:

          break;
        default:

          break;
      }
    }
    return Promise.reject(error.response);
  }
);

export default service;
