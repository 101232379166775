<!--
 * @Author: your name
 * @Date: 2019-12-13 11:48:40
 * @LastEditTime: 2021-01-08 15:07:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-teacherspace-web/src/components/baseComon/TheFooter.vue
 -->
<template>
  <div v-if="sourceFrom" class="footer">
    <!-- pc -->
    <footer>
      <!-- left-->
      <div class="contact-details">
        <div class="top">
          <span v-if="fromType==='educ'" class="call-icon-os" />
          <span v-else class="call-icon" />
          <h3 v-if="fromType==='desk'">
            招商热线
          </h3>
          <h3 v-else class="os-h3">
            学校合作热线
          </h3>
        </div>
        <div :class="fromType==='educ'?'os-center center':'center'">
          400-870-1117
        </div>
      </div>
      <!-- center -->
      <div class="contact-address">
        <!-- div class="top">
          <a class="link-a">
            <i class="sina-icon" />
            <h3>官方微博</h3>
          </a>
          <a class="link-a">
            <i class="wchat-icon" />
            <h3>官方微信</h3>
          </a>
          <a class="link-a">
            <i class="tmall-icon" />
            <h3>天猫商城</h3>
          </a>
        </div -->
        <div class="center">
          湖南省长沙市岳麓山大学科技城岳麓街道溁左路中南大学科技园研发总部 5 栋 201 号
        </div>
        <div class="bottom">
          新云网科技集团股份有限公司版权所有 Copyright ©2022<a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备18015340号-4</a>
        </div>
      </div>
      <div class="down-app">
        <div class="common">
          <img src="../../assets/images/index/QCMall.jpg">
          <!-- <span>优智多商城APP</span> -->
          <span>课桌先生官方商城</span>
        </div>
        <div class="common">
          <img src="../../assets/images/index/QCW.jpg">
          <span>优智多教育公众号</span>
        </div>
      </div>
    </footer>
    <!-- 移动端 -->
    <section class="mb-footer">
      <div class="top">
        <a v-if="fromType==='desk'" class="tel-a" href="tel:4008701117">
          <img src="../../assets/images/moblie/tel-def.png">
          <h3>400-870-1117</h3>
        </a>
        <div v-else class="other-box">
          <a class="tel-a" href="tel:4008701117">
            <img src="../../assets/images/moblie/tel-yellow.png">
            <h3 class="mobile-text">学校合作热线</h3>
          </a>
          <h3 class="mobile-phone">
            400-870-1117
          </h3>
        </div>
        <span class="address">湖南省长沙市岳麓山大学科技城岳麓街道溁左路中南大学科技园研发总部 5 栋 201 号</span>
      </div>

      <div class="bottom">
        <p>新云网科技集团股份有限公司版权所有</p>
        <p><a href="https://beian.miit.gov.cn/" target="_blank">Copyright ©2022湘ICP备18015340号-4</a></p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    fromType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 网页使用场景
      sourceFrom: true
    };
  },
  mounted() {
    this.sourceFrom = this.$route.query.source !== 'desk';
  }
};
</script>
<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .footer{
    height:auto;
    width: 100%;
    background:#ECECEC;
    footer{
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      color: #1E1E1E;
      font-size:12px;
      display: flex;
      justify-content: space-between;
      padding: 55px 0 50px;
      .contact-details{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .top{
          display: flex;
          align-items: center;
          .call-icon{
            width:33px;
            height:33px;
            background: url('../../assets/images/index/tell.png');
          }
          .call-icon-os{
            width:33px;
            height:33px;
            background: url('../../assets/images/os/tellOs.png');
          }
          h3{
            letter-spacing: 6px;
            font-size:34px;
            line-height:26px;
            margin-left: 16px;
            color:#41B1E6;
            font-weight: 600;
          }
          .os-h3{
            color: #FD9C02;
          }
        }
        .center{
          text-align: center;
          margin: 6px 0;
          font-size: 37px;
          color: #41B1E6;
          font-weight: 600;
        }
        .os-center{
          color: #333333;
        }
      }
      .contact-address{
        border-left: 1px solid rgba(191,191,191,1);
        border-right: 1px solid rgba(191,191,191,1);
        padding: 0 34px 0 29px;
        height: 135px;
        width: 45%;
        .top{
          display: flex;
          margin-bottom: 24px;
          .link-a{
            display: flex;
            align-items: center;
            border-right:1px solid #8A8684 ;
            padding: 0 27px 0 42px;
            height: 18px;
            &:nth-of-type(1){
              padding-left: 0;
            }
            &:nth-of-type(3){
              border-right:none;
            }
            i{
              display: inline-block;
            }
            .sina-icon{
              width:29px;
              height:23px;
              background: url('../../assets/images/index/sina.png')
            }
            .wchat-icon{
              width:31px;
              height:23px;
              background: url('../../assets/images/index/wchat.png')
            }
            .tmall-icon{
              width:22px;
              height:25px;
              background: url('../../assets/images/index/Tmall.png')
            }
            h3{
              font-weight: 100;
              font-size:22px;
              color:#8A8684;
              letter-spacing:4px;
              margin-left: 6px;
            }
          }
        }
        .center{
          font-size:16px;
          color:rgba(30,30,30,0.85);
          margin: 17px 0 15px;
          letter-spacing:2px;
        }
        .bottom{
          font-size:17px;
          color:rgba(42,42,42,0.79);
        }
      }
      .down-app{
        display: flex;
        justify-content: center;
        align-items: center;
        .common{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: 54px;
          img{
            width: 95px;
            height: 95px;
            margin-bottom: 6px;
          }
          span{
            font-size:10px;
            color:rgba(10,10,10,1);
          }
        }
      }
    }
  }
  .mb-footer{
    display: none;
  }
}
@media screen and (min-width:992px) and (max-width: 1200px){
  .mb-footer {
    text-align: center !important;
  }
}
@media screen and (max-width: 1200px) {
  footer{
    display: none;
  }
  .mb-footer{
    width: 100%;
    background: #FCF8F5;
    padding: .35rem 0 1.6rem;
    .top{
      color: #8A8684;
      border-bottom: 1px solid rgba(211, 205, 200, 1);
      padding-bottom: .53rem;
      .other-box{
        text-align: center;
        margin-top: 5px;
      }
      .tel-a{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          display: inline-block;
          width: .48rem;
          height: auto;
          margin-right: .187rem;
        }
        h3{
          font-size: .48rem;
        }
        .mobile-text{
          font-size: 22px;
          font-weight: 400;
          color: #FD9C02;
        }
        .mobile-phone{
          font-size: 26px;
          font-weight: bold;
          color: #333333;
          text-align: center;
        }
      }
      .address{
        padding: 0 1rem;
        margin-top: .48rem;
        display: inline-block;
        text-align: center;
        font-size:.32rem;
      }
    }
    .bottom{
      font-size: .32rem;
      color: #999999;
      font-weight: 400;
      padding-top: .53rem;
      p{
        text-align: center;
      }
    }
  }
}
</style>
