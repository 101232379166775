/*
 * @Author: your name
 * @Date: 2020-05-12 16:54:17
 * @LastEditTime: 2020-12-31 11:05:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/router/index.js
 */
import Vue from 'vue';
import Router from 'vue-router';
import Home from './home.js';
// import News from './news.js';
import Concept from './concept.js';
import Product from './product.js';
import JoinPromotion from './joinPromotion.js';
import osHome from './osHome.js';
import Index from '@/views/index.vue';
import Education from './education.js';
import Classroom from './classroom.js';
import Website from './website.js';
import TerminalApplication from '@/views/terminalApplication/index.vue';
import CoreProducts from '@/views/coreProducts/index.vue';
import SmartEducation from '@/views/smartEducation/index.vue';
// import OS from '@/views/OS/index.vue';
// import Cookie from 'js-cookie';
// import storage from '@/utils/storage.js';
Vue.use(Router); // 全局注册Router

const router = new Router({
  // mode: 'history',
  // base:"teacherweb",
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index,
      redirect: 'OS',
      children: [
        ...Home,
        ...Concept,
        // ...News,
        ...Product,
        ...JoinPromotion,
        ...osHome,
        ...Education,
        ...Classroom,
        ...Website
      ]
    },
    // {
    //   path: '/index',
    //   component: OS,
    //   redirect: '/index',
    //   children: [
    //     ...osHome,
    //   ]
    // },
    // 页面重定向
    { path: '*', redirect: '/indexOs' },
    {
      path: '/terminalApplication/index',
      name: 'TerminalApplication',
      component: TerminalApplication
    },
    {
      path: '/coreProducts/index',
      name: 'CoreProducts',
      component: CoreProducts
    },
    {
      path: '/smartEducation/index',
      name: 'SmartEducation',
      component: SmartEducation
    }
  ]
});
// 路由变化，定位至页面顶部（mb端）
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.query && to.query.source === 'wx') {
    document.title = '新云网科技集团股份有限公司';
  } else {
    document.title = '新云网科技集团股份有限公司优智多官网';
  }
  next();
});
export default router;
