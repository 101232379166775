<template>
  <div class="nav-header" @touchmove.prevent>
    <div class="nav-mian">
      <nav class="header-box">
        <div class="nav-left">
          <img src="../../assets/images/os/logoOs.jpg">
          <span class="split-line">|</span>
          <label class="nav-title">
            趣味教学平台
          </label>
        </div>
        <ul class="nav-list">
          <li>
            <router-link to="/educ/educationPlatform">
              首页
            </router-link>
          </li>
          <span class="split-line">|</span>
          <li>
            <router-link to="/educ/educTools">
              教学工具
            </router-link>
          </li>
          <span class="split-line">|</span>
          <li>
            <router-link to="/educ/downCenter">
              下载中心
            </router-link>
          </li>
          <span class="split-line">|</span>
          <li>
            <router-link to="/educ/contactInfo">
              联系方式
            </router-link>
          </li>
        </ul>
        <!-- mb -->
        <i :class="showNavStatus?'nav-icon-close':'nav-icon-open'" @click="openNav()" />
        <ul :class="showNavStatus?'nav-list-mb':'nav-list-mb-open'">
          <li v-for="(item,index) in navList" :key="index" :class="item.addStatus?'otherLinkTo':''" @click="closeNav()">
            <!-- v-if="item.linkType==='router'" -->
            <router-link class="linkTo" :to="item.linkUrl">
              {{ item.title }}
              <i class="arrow-r" />
            </router-link>
            <!-- <a v-else :href="item.linkUrl" class="linkTo" target="_blank">
              {{ item.title }}
              <i class="arrow-r" />
            </a> -->
          </li>
          <li class="tel">
            <a class="tel-a" href="tel:4008701117">
              <i />
              <span>400-870-1117</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
// 菜单数据
export default {
  name: 'NavHeader',
  data() {
    return {
      showNavStatus: false,
      navList: [{
        title: '首页',
        linkUrl: '/educ/educationPlatform',
        linkType: 'router'
      }, {
        title: '教学工具',
        linkUrl: '/educ/educTools',
        linkType: 'router'
      }, {
        title: '下载中心',
        linkUrl: '/educ/downCenter',
        linkType: 'router'
      }, {
        title: '联系方式',
        linkUrl: '/educ/contactInfo',
        linkType: 'router'
      }, {
        title: '新云网科技集团股份有限公司优智多官网',
        linkUrl: '/indexOs/osHome',
        linkType: 'router',
        addStatus: true
      }, {
        title: '课桌先生',
        linkUrl: '/desk/home',
        linkType: 'router',
        addStatus: true
      }, {
        title: '交互式多功能教室',
        linkUrl: '/room/multiClassroom',
        linkType: 'router',
        addStatus: true
      },
      {
        title: '课桌先生官方商城', // 优智多商城
        linkUrl: 'http://www.uaregood.net',
        linkType: 'linklinkOther',
        addStatus: true
      }]
    };
  },
  methods: {
    openNav() {
      this.showNavStatus = !this.showNavStatus;
    },
    closeNav() {
      this.showNavStatus = false;
    }
  }
};
</script>
<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .nav-header{
    height:auto;
    width: 100%;
    background: #fff;
    // 一级导航菜单
    .nav-mian{
      height:85px;
      width: 100%;
      position: relative;
      box-shadow:0px 3px 7px rgba(215,194,177,0.5);
      z-index: 8;
      .header-box{
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #313131;
        .nav-left{
          display: flex;
          align-items: center;
          .split-line{
            color: #E2E2E2;
            padding: 0 20px;
            font-size: 20px;
          }
          img{
            width: 170px;
            height: 47px;
          }
          .nav-title{
            font-size: 30px;
            font-weight: 400;
            color: #555555;
          }
        }
        .nav-list{
          display: flex;
          align-items: center;
          height: 100%;
          font-size: 18px;
          font-weight: 400;
          color: #535353;
          .split-line{
            color: #f0f0f0;
            padding-left: 50px;
          }
          li{
            width: auto;
            text-align: center;
            height: 100%;
            margin-left: 70px;
            display: flex;
            align-items: center;
            &:nth-of-type(1){
              margin-left: 0;
            }
            a{
              height: 100%;
              width: auto;
              display: flex;
              align-items: center;
              &:hover{
                color: #FD9C02;
                font-weight: 600;
              }
            }
            .router-link-active{
              color: #FD9C02;
              font-weight: 600;
            }
          }
        }
        .nav-icon-open,.nav-icon-close,.nav-list-mb,.nav-list-mb-open{
          display: none;
        }
      }
    }

  }
}
@media screen and (max-width: 1200px) {
  .nav-header{
    height:auto;
    width: 100%;
    background: #fff;
    // 一级导航菜单
    .nav-mian{
      height:1.6rem;
      width: 100%;
      position: fixed;
      background: #fff;
      padding:.53rem ;
      box-shadow: 0px 3px 6px rgba(2, 54, 132, 0.16);
      z-index: 8;
      .header-box{
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #313131;
        position: relative;
        img{
          width: 2.4rem;
          height: auto;
        }
        .split-line{
          padding: 0 8px;
          color: #E2E2E2;
          font-size: 20px;
        }
        .nav-title{
          font-size: 18px;
          cursor: pointer;
          font-size: 18px;
          font-weight: 400;
          color: #555555;
        }
        .nav-list{
          display: none;
        }
        .nav-icon-open{
          display: inline-block;
          width:.48rem;
          height:.48rem;
          background: url('../../assets/images/moblie/nav-open.png') no-repeat 100%/100%;
        }
        .nav-icon-close{
          display: inline-block;
          width:.48rem;
          height:.48rem;
          background: url('../../assets/images/moblie/nav-close-yellow.png') no-repeat 100%/100%;
        }
        .nav-list-mb{
          position: fixed;
          left: 0;
          top:1.6rem;
          height: 100%;
          width: 100%;
          background: rgba(255, 255, 255, .9);
          padding:.53rem 1.44rem;
          li{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #D3CDC8;
            padding: .348rem 0 ;
            color: #FD9C02;
            font-size:.426rem ;
            .linkTo{
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            }
            .arrow-r{
              width: .213rem;
              height: .347rem;
              display: inline-block;
              background: url('../../assets/images/moblie/arrowR.png') no-repeat 100%/100%;
            }
          }
          .otherLinkTo{
            color: #A8A9AE;
            border-bottom: 1px dotted #D3CDC8;
          }
          .tel{
            display: flex;
            justify-content: flex-start;
            border: none;
            .tel-a{
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
            i{
              display: inline-block;
              width: .88rem;
              height: .88rem;
              background: url('../../assets/images/moblie/tel-yellow.png') no-repeat 100%/100%;
              margin-right: .187rem;
            }
            span{
              font-size: .506rem;
              line-height: 1.49rem;
              color: #FD9C02;
            }
          }
        }
        .nav-list-mb-open{
          display: none;
        }
      }
    }
  }
}

</style>
