<template>
  <div class="container">
    <div class="articles-header">
      <h2 class="rich-media-title">
        智慧教育生态圈
      </h2>
      <div>
        <a href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzUzNTQ0NjU4Mw==&subscene=0#wechat_redirect" class=" account_nickname_inner">
          <img class="account-avatar" src="http://wx.qlogo.cn/mmhead/Q3auHgzwzM5BZYKvleocyorlbIhY0twYZZJpO1hpkriaVpZasib3rMFw/0" alt="logo">
          <span class="account-name">新云网科技集团股份有限公司</span>
        </a>
      </div>
    </div>
    <div>
      <a href="https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484749&idx=1&sn=68b5306eab0c3b1b27c9116cc510610e&chksm=fa841015cdf39903b6565ed96098d4b150024eb11a40f2e91a910589ae6c419ca48bdbca859b#rd">
        <img class="banner-img" src="../../assets/images/cunOfficialAccount/数字化智慧教育生态圈.png" alt="智慧教务系统">
      </a>
      <p class="desc">
        智慧教育生态圈
      </p>
    </div>
    <div class="tab">
      <div class="tab-hd">
        智慧教育生态圈
      </div>
      <div class="tab-container">
        <div v-for="item in contentData" :key="item.name" class="tab-bd">
          <a :href="item.url" style="display: flex;width:100%;">
            <p>{{ item.name }}</p>
            <img :src="item.src" alt="图片">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contentData: [
        { name: '数字化智慧教育生态圈', src: require('../../assets/images/cunOfficialAccount/数字化智慧教育生态圈.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484749&idx=2&sn=1beae8c6f5a5ec873ecc82ead0f64d24&chksm=fa841015cdf39903d850d18b1f60c332cd20601eea08a47d095fd7d2ac737fb4af25dd4deb87#rd' },
        { name: '生态圈技术支撑', src: require('../../assets/images/cunOfficialAccount/生态圈技术支撑.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484749&idx=3&sn=a7978f3072d39c0e09e6d922f6484871&chksm=fa841015cdf399038d78742e0578dbddc1e071bccdf6699f5eb5882ccc15c9dacfab50edaa80#rd' },
        { name: '教育大数据云平台', src: require('../../assets/images/cunOfficialAccount/教育大数据云平台.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484749&idx=4&sn=fc000885a646b445498b5e0746e7bb02&chksm=fa841015cdf39903fd9f3eac6e35dfeff1eb15f731cfec36211a52cf0095460d2aa71f46e797#rd' },
        { name: '教育资源云平台', src: require('../../assets/images/cunOfficialAccount/教育资源云平台.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484749&idx=5&sn=d27c0d09541606b555fa90deab25b76f&chksm=fa841015cdf3990387e32bb78a11f058f285438a1ae9b19e77797e3f9565acf0c57623423919#rd' },
        { name: '趣味教学平台', src: require('../../assets/images/cunOfficialAccount/趣味教学平台.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484749&idx=6&sn=cc4842111d0c818b626bc3235da0ace0&chksm=fa841015cdf39903ce3239acefac57579c16c1b03092779db3c59f77ab66d9fc2ab7f27e07dd#rd' }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.container {
  background-color: #fff;
  .articles-header {
    padding: 24px 20px 20px;
    .rich-media-title {
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .account-avatar {
      width: 28px;
      height: 28px;
      padding: 0;
      display: inline-block;
      background-color: #fff;
      border-radius: 50%;
      overflow: hidden;
      vertical-align: middle;
    }
    .account-name {
      color: #576b95;
      width: 180px;
      padding-left: 8px;
      font-weight: 400;
      vertical-align: middle;
      font-size: 15px;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space:nowrap;
      display: inline-block;
    }
  }
  .banner-img {
    width: 100%;
    padding: 0 10px;
    height: calc((100vw - 40px) / 2.35);
    background: center center no-repeat;
    background-size: cover;
  }
  .desc {
    font-size: 16px;
    padding: 14px 12px 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    background-color: rgba(0,0,0,0.03);;
  }
  .tab-hd {
    position: relative;
    line-height: 48px;
    color: #09bb07;
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid #ececec;
  }
  .tab-hd::after {
    content: ' ';
    display: block;
    width: 20px;
    height: 3px;
    background-color: #09bb07;
    position: absolute;
    bottom: 0;
    margin-bottom: -0.5px;
    left: 50%;
    margin-left: -10px;
    z-index: 1;
  }
  .tab-container {
    padding: 12px 0px 11px 20px;
    .tab-bd {
      display: flex;
      padding: 12px 0;
      border-bottom: 1px solid #e2e2e2;
      p {
        flex: 1;
        height: 74px;
        line-height: 74px;
        font-size: 17px;
      }
      img {
        padding: 0 10px;
        width: 110px;
        height: 74px;
        object-fit: cover;
      }
    }
    .tab-bd:last-child {
      border:none;
    }
  }
}
</style>
