import Vue from 'vue';
import App from './App.vue';
import router from '@/router';

import axios from '@/utils/request.js';

// 引入数字输入指令
import onlyNumber from '@/directive/onlyNumber.js';
Vue.use(onlyNumber);
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

import FastClick from 'fastclick';
FastClick.attach(document.body);

// 按需加载element组件
import '@/assets/styles/theme/index.css';// 自定义主题
// import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/index.scss'; // global css放在ele-css之后覆盖

import {
  Pagination,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  Select,
  Option,
  Button,
  Table,
  TableColumn,
  DatePicker,
  TimePicker,
  Popover,
  Tooltip,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tree,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Rate,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  Cascader,
  Loading,
  MessageBox,
  Message,
  Scrollbar,
  InfiniteScroll,
  Badge,
  Backtop,
  Slider,
  Autocomplete,
  Switch,
  Breadcrumb,
  BreadcrumbItem,
  Steps,
  Step
} from 'element-ui';
Vue.use(Steps);
Vue.use(Step);
Vue.use(BreadcrumbItem);
Vue.use(Breadcrumb);
Vue.use(Autocomplete);
Vue.use(Slider);
Vue.use(Scrollbar);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tree);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Rate);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Cascader);
Vue.use(Loading.directive);
Vue.use(InfiniteScroll);
Vue.use(Badge);
Vue.use(Backtop);
Vue.use(Switch);
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app');
