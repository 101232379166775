<!--
 * @Author: your name
 * @Date: 2020-05-29 09:20:07
 * @LastEditTime: 2020-11-27 17:18:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS-two/src/components/baseComon/TheTopHeader.vue
-->
<template>
  <section class="top-header" :class="[typeView=='desk'||typeView=='room' ? 'buleBack':'yellow']">
    <div class="top-header-center">
      <ul class="right">
        <li v-show="typeView!='desk'&&from=='cunw'" class="setting">
          <a class="linkTo" href="http://www.cunw.com.cn/">
            <span class="explain">新云网首页</span>
          </a>
        </li>
        <li v-show="typeView=='desk'&&from=='ai'" class="setting">
          <a class="linkTo" :href="aiUrl|env">
            <span class="explain">返回AI启蒙编程首页</span>
          </a>
        </li>
        <template v-if="from!='cunw'&&from!='ai'">
          <li class="setting">
            <a class="linkTo" @click="$router.push('/indexOs/osHome')">
              <span class="explain">优智多首页</span>
            </a>
          </li>
          <li v-show="typeView!='desk'" class="setting">
            <a class="linkTo" @click="$router.push('/desk/home')">
              <span class="explain">课桌先生</span>
            </a>
          </li>
          <li v-show="typeView!='qjx'" class="setting">
            <a class="linkTo" @click="$router.push('/educ/educationPlatform')">
              <span class="explain">优智多趣味教学平台</span>
            </a>
          </li>
          <li v-show="typeView!='room'" class="setting" @click="$router.push('/room/multiClassroom')">
            <a class="linkTo">
              <span class="explain">交互式多功能教室</span>
            </a>
          </li>
          <!-- <li class="setting">
          <a class="linkTo" href="http://www.cunwedu.com.cn" target="_blank">
            <span class="explain desk-class">优智多课堂</span>
          </a>
        </li> -->
          <li class="setting">
            <a class="linkTo" href="http://www.uaregood.net" target="_blank">
              <span class="explain">课桌先生官方商城</span>
            </a>
          </li>
        </template>
      </ul>
    </div>
  </section>
</template>

<script>

export default {
  name: 'TopHeader',
  filters: {
    env(value) {
      if (process.env.VUE_APP_TITLE === 'test') {
        let url = value.split('www');
        url = url[0] + 'www-test' + url[1];
        return url;
      }
      return value;
    }
  },
  props: {
    typeView: {
      type: String,
      value: ''
    }
  },
  data() {
    return {
      aiUrl: 'http://www.uaregood.cn/'
    };
  },
  computed: {
    from() {
      return this.$route.query.from;
    }
  }
};
</script>
<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .top-header {
    height: 39px;
    width: 100%;
    color: #FFFFFF;
    &.buleBack{
      background: #41B1E6;
    }
    &.yellow{
      background: #FD9C02;
    }
    .top-header-center {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tel{
          font-size:26px;
        }
        .desk-class{
          display: inline-block;
          border-left: 2px solid #fff;
          border-right: 2px solid #fff;
          padding: 0 24px;
          height: 18px;
          line-height: 18px;
        }
        .setting {
          margin-left: 26px;
          .linkTo {
            display: block;
            height: 100%;
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size:16px;
            .iconBg {
              display: inline-block;
              width: 22px;
              height: 19px;
              margin-right: 5px;
            }
            .message {
              background: url("../../assets/images/home/youzhiduo_icon.png");
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .top-header{
    display: none;
  }
}

</style>
