<template>
  <div class="nav-header" @touchmove.prevent>
    <div class="nav-mian">
      <nav class="header-box">
        <router-link to="/desk/home" class="logo">
          <img src="../../assets/images/home/logo.png">
        </router-link>
        <ul class="nav-list">
          <li>
            <router-link to="/desk/home">
              首页
            </router-link>
          </li>
          <li v-if="from=='ai'">
            <a class="linkTo" href="http://www.uaregood.net" target="_blank">
              <span class="explain">课桌先生官方商城</span>
            </a>
          </li>
          <li>
            <router-link to="/desk/concept">
              品牌故事
            </router-link>
          </li>
          <li>
            <router-link to="/desk/product">
              产品中心
            </router-link>
          </li>
          <li>
            <router-link to="/desk/joinPromotion">
              招商加盟
            </router-link>
          </li>
          <li>
            <router-link to="/desk/afterSaleService">
              售后服务
            </router-link>
          </li>
        </ul>
        <!-- mb -->
        <i :class="showNavStatus?'nav-icon-close':'nav-icon-open'" @click="openNav()" />
        <ul :class="showNavStatus?'nav-list-mb':'nav-list-mb-open'">
          <li v-for="(item,index) in (from=='ai'?navListAi:navList)" :key="index" :class="item.addStatus?'otherLinkTo':''" @click="closeNav()">
            <router-link v-if="item.linkType==='router'" class="linkTo" :to="item.linkUrl">
              {{ item.title }}
              <i v-if="!item.addStatus" class="arrow-r" />
            </router-link>
            <a v-else :href="item.linkUrl" class="linkTo" target="_blank">
              {{ item.title }}
              <i v-if="!item.addStatus" class="arrow-r" />
            </a>
          </li>
          <li v-if="from=='ai'" class="otherLinkTo" @click="closeNav()">
            <a :href="aiUrl|env" class="linkTo" style="font-weight:bold;color:#333">
              返回AI启蒙编程首页
            </a>
          </li>
          <li class="tel">
            <a class="tel-a" href="tel:4008701117">
              <i />
              <span>400-870-1117</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
// 菜单数据
export default {
  name: 'NavHeader',
  filters: {
    env(value) {
      if (process.env.VUE_APP_TITLE === 'test') {
        let url = value.split('www');
        url = url[0] + 'www-test' + url[1];
        return url;
      }
      return value;
    }
  },
  data() {
    return {
      aiUrl: 'http://www.uaregood.cn/',
      showNavStatus: false,
      navListAi: [{
        title: '首页',
        linkUrl: '/desk/home',
        linkType: 'router'
      }, {
        title: '课桌先生官方商城',
        linkUrl: 'http://www.uaregood.net',
        linkType: 'link'
      }, {
        title: '品牌故事',
        linkUrl: '/desk/concept',
        linkType: 'router'
      }, {
        title: '产品中心',
        linkUrl: '/desk/product',
        linkType: 'router'
      }, {
        title: '招商加盟',
        linkUrl: '/desk/joinPromotion',
        linkType: 'router'
      }, {
        title: '售后服务',
        linkUrl: '/desk/afterSaleService',
        linkType: 'router'
      }
      ],
      navList: [{
        title: '首页',
        linkUrl: '/desk/home',
        linkType: 'router'
      }, {
        title: '品牌故事',
        linkUrl: '/desk/concept',
        linkType: 'router'
      }, {
        title: '产品中心',
        linkUrl: '/desk/product',
        linkType: 'router'
      }, {
        title: '招商加盟',
        linkUrl: '/desk/joinPromotion',
        linkType: 'router'
      }, {
        title: '售后服务',
        linkUrl: '/desk/afterSaleService',
        linkType: 'router'
      }, {
        title: '新云网科技集团股份有限公司优智多官网',
        linkUrl: '/indexOs/osHome',
        linkType: 'router',
        addStatus: true
      }, {
        title: '优智多趣味教学平台',
        linkUrl: '/educ/educationPlatform',
        linkType: 'router',
        addStatus: true
      }, {
        title: '交互式多功能教室',
        linkUrl: '/room/multiClassroom',
        linkType: 'router',
        addStatus: true
      },
      {
        title: '课桌先生官方商城', // 优智多商城
        linkUrl: 'http://www.uaregood.net',
        linkType: 'linklinkOther',
        addStatus: true
      }]
    };
  },
  computed: {
    from() {
      return this.$route.query.from;
    }
  },
  methods: {
    openNav() {
      this.showNavStatus = !this.showNavStatus;
    },
    closeNav() {
      this.showNavStatus = false;
    }
  }
};
</script>
<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .nav-header{
    height:auto;
    width: 100%;
    background: #fff;
    // 一级导航菜单
    .nav-mian{
      height:85px;
      width: 100%;
      position: relative;
      box-shadow:0px 3px 7px rgba(215,194,177,0.5);
      z-index: 8;
      .header-box{
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #313131;
        .logo{
          img{
            width: 139.3px;
            height: auto;
          }
        }
        .logo-title{
          font-size: 24px;
          cursor: pointer;
        }
        .nav-list{
          display: flex;
          align-items: center;
          height: 100%;
          font-size: 16px;
          li{
            width: auto;
            text-align: center;
            height: 100%;
            margin-left: 70px;
            display: flex;
            align-items: center;
            &:nth-of-type(1){
              margin-left: 0;
            }
            a{
              height: 100%;
              width: auto;
              display: flex;
              align-items: center;
              &:hover{
                color: #2AA5D4;
              }
            }
            .router-link-active{
              color: #2AA5D4;
            }
          }
        }
        .nav-icon-open,.nav-icon-close,.nav-list-mb,.nav-list-mb-open{
          display: none;
        }
      }
    }

  }
}
@media screen and (max-width: 1200px) {
  .nav-header{
    height:auto;
    width: 100%;
    background: #fff;
    // 一级导航菜单
    .nav-mian{
      height:1.6rem;
      width: 100%;
      position: fixed;
      background: #fff;
      padding:.53rem ;
      box-shadow: 0px 3px 6px rgba(2, 54, 132, 0.16);
      z-index: 8;
      .header-box{
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #313131;
        position: relative;
        .logo{
          img{
            width: 2.4rem;
            height: auto;
          }
        }
        .logo-title{
          font-size: 24px;
          cursor: pointer;
        }
        .nav-list{
          display: none;
        }
        .nav-icon-open{
          display: inline-block;
          width:.48rem;
          height:.48rem;
          background: url('../../assets/images/moblie/nav-open.png') no-repeat 100%/100%;
        }
        .nav-icon-close{
          display: inline-block;
          width:.48rem;
          height:.48rem;
          background: url('../../assets/images/moblie/nav-close.png') no-repeat 100%/100%;
        }
        .nav-list-mb{
          position: fixed;
          left: 0;
          top:1.6rem;
          height: 100%;
          width: 100%;
          background: rgba(255, 255, 255, .9);
          padding:.53rem 1.44rem;
          li{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #D3CDC8;
            padding: .348rem 0 ;
            color: #27A5E2;
            font-size:.426rem ;
            .linkTo{
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            }
            .arrow-r{
              width: .213rem;
              height: .347rem;
              display: inline-block;
              background: url('../../assets/images/moblie/arrowR.png') no-repeat 100%/100%;
            }
          }
          .otherLinkTo{
            color: #A8A9AE;
            border-bottom: 1px dotted #D3CDC8;
          }
          .tel{
            display: flex;
            justify-content: flex-start;
            border: none;
            .tel-a{
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
            i{
              display: inline-block;
              width: .88rem;
              height: .88rem;
              background: url('../../assets/images/moblie/tel-blue.png') no-repeat 100%/100%;
              margin-right: .187rem;
            }
            span{
              font-size: .506rem;
              line-height: 1.49rem;
              color: #27A5E2;
            }
          }
        }
        .nav-list-mb-open{
          display: none;
        }
      }
    }
  }
}

</style>
