<template>
  <div class="container">
    <div class="articles-header">
      <h2 class="rich-media-title">
        核心产品
      </h2>
      <div>
        <a href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzUzNTQ0NjU4Mw==&subscene=0#wechat_redirect" class=" account_nickname_inner">
          <img class="account-avatar" src="http://wx.qlogo.cn/mmhead/Q3auHgzwzM5BZYKvleocyorlbIhY0twYZZJpO1hpkriaVpZasib3rMFw/0" alt="logo">
          <span class="account-name">新云网科技集团股份有限公司</span>
        </a>
      </div>
    </div>
    <div>
      <a href="https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484751&idx=1&sn=48575bb9410f84f9819517dc90c7973e&chksm=fa841017cdf399011368a3d74dee6d63ce5a11a46d74c396652eef58f52d06a32de990e5c94a#rd">
        <img class="banner-img" src="../../assets/images/cunOfficialAccount/交互式智慧黑板.png" alt="交互式智慧黑板">
      </a>
      <p class="desc">
        核心产品
      </p>
    </div>
    <div class="tab">
      <div class="tab-hd">
        核心产品
      </div>
      <div class="tab-container">
        <div v-for="item in contentData" :key="item.name" class="tab-bd">
          <a :href="item.url" style="display: flex;width:100%;">
            <p>{{ item.name }}</p>
            <img :src="item.src" alt="智慧教务">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contentData: [
        { name: '交互式智慧黑板', src: require('../../assets/images/cunOfficialAccount/交互式智慧黑板.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484765&idx=1&sn=af14e95bc576d36e82be250dd55af0ff&chksm=fa841005cdf39913869f9aaad4ef697046a3b364e53723d9788d5c791eb292b52fdb482db044#rd' },
        { name: '交互式智慧课桌', src: require('../../assets/images/cunOfficialAccount/交互式智慧课桌.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484765&idx=2&sn=d60daa580ea28b0ad37452f9eea22122&chksm=fa841005cdf39913c14f692dc763b1b02df1601cd98f43a25c2a42b3a550d64adf497b8ca868#rd' },
        { name: '交互式智慧校牌', src: require('../../assets/images/cunOfficialAccount/交互式智慧校牌.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484765&idx=3&sn=a02bc34020c3a632bb0bdb086db8f2e0&chksm=fa841005cdf39913fef1b5a803f67547a8d08c43a4cc45ade187110cd2ec747a46cc10079468#rd' },
        { name: '交互式智慧平板', src: require('../../assets/images/cunOfficialAccount/交互式智慧平板.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484765&idx=4&sn=93893711156ff5461fa99d5729abd490&chksm=fa841005cdf39913a8e5371668d4c66ce58c13ee3903241e5642b007bcd64202e721fef239a0#rd' },
        { name: '智慧电子学生证', src: require('../../assets/images/cunOfficialAccount/智慧电子学生证.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484765&idx=5&sn=ebd83e143bec5fa53367bc6de1546031&chksm=fa841005cdf39913d75d99bab6e0f853ee5213a7247c1791e3a73c9d0fc0928af4b84ef1fd8c#rd' },
        { name: '校医工作台', src: require('../../assets/images/cunOfficialAccount/校医工作台.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484765&idx=6&sn=ded3626a64b4845c1c13c2cff52c750c&chksm=fa841005cdf39913ff74d92348dca813f980f8aa185e2d5e0fd60d0bf62141fffccdaa708810#rd' },
        { name: '智慧朗读亭', src: require('../../assets/images/cunOfficialAccount/智慧朗读亭.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484765&idx=7&sn=74416a5e1db3e6cca11ef8bdbda8fd16&chksm=fa841005cdf39913d181bb73ed00e5d7e8fed7475acf024f2ce23d66e729b8d36d8fdd9c08b9#rd' },
        { name: '智慧家教学习桌', src: require('../../assets/images/cunOfficialAccount/智慧家教学习桌.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484765&idx=8&sn=3e0f6ca8f0a62c937dd2b31f9ab2a285&chksm=fa841005cdf39913684c41b01388680663b67488aa767a38b2e52ed08592ecd87471e8e66e07#rd' }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.container {
  background-color: #fff;
  .articles-header {
    padding: 24px 20px 20px;
    .rich-media-title {
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .account-avatar {
      width: 28px;
      height: 28px;
      padding: 0;
      display: inline-block;
      background-color: #fff;
      border-radius: 50%;
      overflow: hidden;
      vertical-align: middle;
    }
    .account-name {
      color: #576b95;
      width: 180px;
      padding-left: 8px;
      font-weight: 400;
      vertical-align: middle;
      font-size: 15px;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space:nowrap;
      display: inline-block;
    }
  }
  .banner-img {
    width: 100%;
    padding: 0 10px;
    height: calc((100vw - 40px) / 2.35);
    background: center center no-repeat;
    background-size: cover;
  }
  .desc {
    font-size: 16px;
    padding: 14px 12px 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    background-color: rgba(0,0,0,0.03);;
  }
  .tab-hd {
    position: relative;
    line-height: 48px;
    color: #09bb07;
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid #ececec;
  }
  .tab-hd::after {
    content: ' ';
    display: block;
    width: 20px;
    height: 3px;
    background-color: #09bb07;
    position: absolute;
    bottom: 0;
    margin-bottom: -0.5px;
    left: 50%;
    margin-left: -10px;
    z-index: 1;
  }
  .tab-container {
    padding: 12px 0px 11px 20px;
    .tab-bd {
      display: flex;
      padding: 12px 0;
      border-bottom: 1px solid #e2e2e2;
      p {
        flex: 1;
        height: 74px;
        line-height: 74px;
        font-size: 17px;
      }
      img {
        padding: 0 10px;
        width: 110px;
        height: 74px;
        object-fit: cover;
      }
    }
    .tab-bd:last-child {
      border:none;
    }
  }
}
</style>
