// 首页
const routes = [
  {
    path: '/educ/educationPlatform', component: resolve => require(['@/views/educationPlatform/index.vue'], resolve)
  },
  {
    path: '/educ/educTools', component: resolve => require(['@/views/educationPlatform/educTools.vue'], resolve)
  },
  {
    path: '/educ/downCenter', component: resolve => require(['@/views/educationPlatform/downCenter.vue'], resolve)
  },
  {
    path: '/educ/contactInfo', component: resolve => require(['@/views/educationPlatform/contactInfo.vue'], resolve)
  },
  {
    path: '/educ/appDetail', component: resolve => require(['@/views/educationPlatform/appDetail.vue'], resolve)
  }
];
export default routes;
