/*
 * @Author: your name
 * @Date: 2019-12-20 18:08:20
 * @LastEditTime: 2020-12-31 11:44:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-teacherspace-web/src/utils/environment.js
 */

let schoolBaseUrl = ''; // 这里是一个默认的url，可以没有

if (process.env.NODE_ENV === 'development') {
  // http://116.205.161.70:7763/
  // schoolBaseUrl = `http://116.205.183.90:7763/`; // 红波本地
  schoolBaseUrl = `https://test.cunwedu.com.cn/wx`; // 测试
} else {
  if (process.env.VUE_APP_TITLE === 'dev') {
    schoolBaseUrl = `https://test.cunwedu.com.cn/wx`;// 后端局域网开发编译
  } else if (process.env.VUE_APP_TITLE === 'test') {
    schoolBaseUrl = `https://test.cunwedu.com.cn/wx`; // 测试环境
  } else {
    schoolBaseUrl = `https://gw.cunwedu.com.cn/wx`; // 生产环境
  }
}

const ENVIRONMENT = {
  API_SCHOOL_URL: schoolBaseUrl // api访问地址
};

export default ENVIRONMENT;
