<!--
 * @Author: your name
 * @Date: 2020-04-09 16:09:28
 * @LastEditTime: 2020-12-30 19:17:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/index.vue
-->
<template>
  <div class="enter-block">
    <!-- 课桌先生头部 -->
    <!-- <div v-if="!catchPage&&sourceFrom" class="topHeader"> -->
    <div v-show="key.indexOf('/desk')==0" class="topHeader">
      <topHeader :type-view="'desk'" />
      <navHeader />
    </div>
    <!-- 优智多头部 -->
    <!-- v-else-if="sourceFrom" -->
    <div v-show="key.indexOf('/indexOs')==0" class="topHeader">
      <TheHeaderOs />
    </div>
    <!-- 趣教学头部 -->
    <div v-show="key.indexOf('/educ')==0">
      <topHeader :type-view="'qjx'" />
      <NavHeaderQjx />
    </div>
    <div v-show="key.indexOf('/room')==0">
      <topHeader :type-view="'room'" />
      <NavHeaderRoom />
    </div>
    <!-- 主体 -->
    <el-scrollbar
      ref="myScrollbar"
      :native="false"
      wrap-style
      wrap-class
      view-class
      view-style
      :noresize="false"
      tag="section"
      :class="sourceFrom?'main-special-all':'main-special-all main-special-all-wx'"
    >
      <main>
        <router-view />
        <!-- <viewContainer class="page" /> -->
      </main>
      <Footer v-if="sourceFrom" :from-type="key.indexOf('/desk')==0 ? 'desk':'educ'" />
    </el-scrollbar>
  </div>
</template>
<script>
// import viewContainer from '@/components/viewContainer';
import topHeader from '@/components/baseComon/TheTopHeader';
import navHeader from '@/components/baseComon/TheHeader';
import NavHeaderQjx from '@/components/baseComon/TheHeaderQjx';
import Footer from '@/components/baseComon/TheFooter';

import TheHeaderOs from '@/components/baseComon/TheHeaderOs';
import NavHeaderRoom from '@/components/baseComon/TheHeaderRoom';
const catchList = [
  '/indexOs'
];

export default {
  name: 'Index',
  components: { topHeader, navHeader, Footer, TheHeaderOs, NavHeaderQjx, NavHeaderRoom },
  data() {
    return {
      // 网页使用场景
      sourceFrom: true
    };
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
    catchPage() {
      // console.log(!catchList.some(item => { this.$route.fullPath.indexOf(item) !== -1; }));
      return catchList.some(item => {
        return this.$route.fullPath.indexOf(item) !== -1;
      });
    }
  },
  watch: {
    // 监听路由变化，定位至页面顶部（pc端）
    $route(to, from) {
      document.querySelector('.el-scrollbar__wrap').scrollTop = 0;
    }
  },
  mounted() {
    this.sourceFrom = !this.$route.query.source;
  }
};
</script>
<style lang="scss" scope>
@media screen and (min-width: 1200px) {
  .main-special-all {
    height: auto;
    background: #F8F8F8;
    .el-scrollbar__wrap {
      &:nth-of-type(1) {
        overflow-x: hidden !important;
      }
    }
  }
  .enter-block{
    display: flex;
    flex-direction: column;
    height:100%
  }
}
@media screen and (min-width:993px) and (max-width: 1200px){
  .main-special-all {
    background: #F8F8F8;
  }
}
@media screen and (max-width: 1200px) {
  .main-special-all{
    margin-top: 1.6rem;
  }
  .main-special-all-wx{
    margin-top: 0;
  }
  .enter-block{
    display: flex;
    flex-direction: column;
  }
}
</style>
