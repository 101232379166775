<template>
  <div class="container">
    <div class="articles-header">
      <h2 class="rich-media-title">
        终端融合应用
      </h2>
      <div>
        <a href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzUzNTQ0NjU4Mw==&subscene=0#wechat_redirect" class=" account_nickname_inner">
          <img class="account-avatar" src="http://wx.qlogo.cn/mmhead/Q3auHgzwzM5BZYKvleocyorlbIhY0twYZZJpO1hpkriaVpZasib3rMFw/0" alt="logo">
          <span class="account-name">新云网科技集团股份有限公司</span>
        </a>
      </div>
    </div>
    <div>
      <a href="https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484769&idx=1&sn=26cf1d69e056fca3a2892fb4272fb04c&chksm=fa841039cdf3992f08f468fcc9210de6fe9acc3cb1f3e5d05a30f69a950419e3e01c1df89a25#rd">
        <img class="banner-img" src="../../assets/images/cunOfficialAccount/智慧教务系统.png" alt="智慧教务系统">
      </a>
      <p class="desc">
        终端融合应用
      </p>
    </div>
    <div class="tab">
      <div class="tab-hd">
        终端融合应用
      </div>
      <div class="tab-container">
        <div v-for="item in contentData" :key="item.name" class="tab-bd">
          <a :href="item.url" style="display: flex;width:100%;">
            <p>{{ item.name }}</p>
            <img :src="item.src" alt="图片">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contentData: [
        { name: '智慧教务系统', src: require('../../assets/images/cunOfficialAccount/智慧教务系统.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484752&idx=1&sn=8aee35c1126b005329f3f425dd20691a&chksm=fa841008cdf3991eb2853481fc540241bca1416cf22cc3a7e58ebf8286d9bc3d16d561d3ba3c#rd' },
        { name: '智慧教学系统', src: require('../../assets/images/cunOfficialAccount/智慧教学系统.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484753&idx=1&sn=d37705b64846a43413cbde9365554371&chksm=fa841009cdf3991fc00809583bb1dfed8b9cc95e557a73bab8aa6175fe0ac089b9d815cc7dc3#rd' },
        { name: '综合素质评价系统', src: require('../../assets/images/cunOfficialAccount/综合素质评价系统.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484756&idx=1&sn=8b02163da6b38ef2b33a35f621440690&chksm=fa84100ccdf3991a1c92818a37fdeab00638bb996c2a4ef497a631e1deb61755131df2500efb#rd' },
        { name: '备授课系统', src: require('../../assets/images/cunOfficialAccount/备授课系统.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484757&idx=1&sn=0a394cd92d42f150568d7110a750cf40&chksm=fa84100dcdf3991b1c2b23b31781a836b8e309c31926a6c1f69fc4d20e25ca0d1c9e049d719b#rd' },
        { name: '教学视导平台', src: require('../../assets/images/cunOfficialAccount/教学视导平台.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484758&idx=1&sn=bcd11e0b3a10fd6ea06a6b54911eebdd&chksm=fa84100ecdf39918d332dcd00e9729b085b9056bef3c949d61aa1285018b8fde7ba6b1af4e46#rd' },
        { name: '课后三点半服务管理平台', src: require('../../assets/images/cunOfficialAccount/课后三点半服务管理平台.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484759&idx=1&sn=97873ca14173d554d44a5e411e06c22f&chksm=fa84100fcdf3991998d8eae87b2a0c68871ba63d0f1dca97344c37cc6a9b3271fbd7f2cccde9#rd' },
        { name: '智慧课堂', src: require('../../assets/images/cunOfficialAccount/智慧课堂.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484760&idx=1&sn=7e70754b6032ea1769b08048b0be86c0&chksm=fa841000cdf399167f875fc8f01c547d9e506b395705151c7444cff8c4e60591e5c84089cfe9#rd' },
        { name: '精品录播课堂', src: require('../../assets/images/cunOfficialAccount/精品录播课堂.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484761&idx=1&sn=e09f948bd2536a4cd4b062e7c8d5a3aa&chksm=fa841001cdf399174d44e068462b56976749ec9838f35286c9a0e7e3982280f63c0bcc1a71f9#rd' },
        { name: '多元智慧教室', src: require('../../assets/images/cunOfficialAccount/多元智慧教室.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484762&idx=1&sn=cb44aed5157e3bd9b23e343628b25bfe&chksm=fa841002cdf39914bb8cfc6e8edc57a1f5664d178a9f481588651636191c0827eda3faad9a97#rd' },
        { name: '数字功能教室', src: require('../../assets/images/cunOfficialAccount/数字功能教室.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484764&idx=1&sn=d1f7c1a36220e6be3a80d021acc63962&chksm=fa841004cdf399120348433dc56c4a4aeefcd20fc956e2e0fc017d5d1543711fb80a187138be#rd' },
        { name: '智慧体育', src: require('../../assets/images/cunOfficialAccount/智慧体育.png'), url: 'https://mp.weixin.qq.com/s?__biz=MzUzNTQ0NjU4Mw==&mid=2247484763&idx=1&sn=124cf75a46ca3c36c6e55bc096ea17ba&chksm=fa841003cdf39915c67c521bc8611f0a64dfce7b1428ac895bf5b43306762a12d95e45f35370#rd' }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.container {
  background-color: #fff;
  .articles-header {
    padding: 24px 20px 20px;
    .rich-media-title {
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .account-avatar {
      width: 28px;
      height: 28px;
      padding: 0;
      display: inline-block;
      background-color: #fff;
      border-radius: 50%;
      overflow: hidden;
      vertical-align: middle;
    }
    .account-name {
      color: #576b95;
      width: 180px;
      padding-left: 8px;
      font-weight: 400;
      vertical-align: middle;
      font-size: 15px;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space:nowrap;
      display: inline-block;
    }
  }
  .banner-img {
    width: 100%;
    padding: 0 10px;
    height: calc((100vw - 40px) / 2.35);
    background: center center no-repeat;
    background-size: cover;
  }
  .desc {
    font-size: 16px;
    padding: 14px 12px 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    background-color: rgba(0,0,0,0.03);;
  }
  .tab-hd {
    position: relative;
    line-height: 48px;
    color: #09bb07;
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid #ececec;
  }
  .tab-hd::after {
    content: ' ';
    display: block;
    width: 20px;
    height: 3px;
    background-color: #09bb07;
    position: absolute;
    bottom: 0;
    margin-bottom: -0.5px;
    left: 50%;
    margin-left: -10px;
    z-index: 1;
  }
  .tab-container {
    padding: 12px 0px 11px 20px;
    .tab-bd {
      display: flex;
      padding: 12px 0;
      border-bottom: 1px solid #e2e2e2;
      p {
        flex: 1;
        height: 74px;
        line-height: 74px;
        font-size: 17px;
      }
      img {
        padding: 0 10px;
        width: 110px;
        height: 74px;
        object-fit: cover;
      }
    }
    .tab-bd:last-child {
      border:none;
    }
  }
}
</style>
