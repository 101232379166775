/*
 * @Author: your name
 * @Date: 2019-12-13 11:35:26
 * @LastEditTime: 2020-11-12 15:54:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-teacherspace-web/src/router/ClassManagement.js
 */

const routes = [
  {
    path: '/desk/concept', component: resolve => require(['@/views/productConcept/index.vue'], resolve)
  }
];
export default routes;

