/*
 * @Author: your name
 * @Date: 2019-12-13 11:35:26
 * @LastEditTime: 2020-11-09 16:19:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-teacherspace-web/src/router/ClassManagement.js
 */

const routes = [
  {
    path: '/indexOs', component: resolve => require(['@/views/OS/index.vue'], resolve),
    children: [
      // 优智多首页
      { path: 'osHome', component: resolve => require(['@/views/OS/osHome.vue'], resolve) },
      // 关于我们
      { path: 'aboutUs', component: resolve => require(['@/views/OS/aboutUs.vue'], resolve) },
      // 新闻列表
      { path: 'newsList', component: resolve => require(['@/views/OS/newsList.vue'], resolve) },
      { path: '', redirect: 'osHome' },
      // 新闻详情1
      { path: 'newsDetails1', component: resolve => require(['@/views/OS/newsDetails1.vue'], resolve) },
      // 新闻详情2
      { path: 'newsDetails2', component: resolve => require(['@/views/OS/newsDetails2.vue'], resolve) },
      // 新闻详情3
      { path: 'newsDetails3', component: resolve => require(['@/views/OS/newsDetails3.vue'], resolve) },
      // 新闻详情4
      { path: 'newsDetails4', component: resolve => require(['@/views/OS/newsDetails4.vue'], resolve) },
      // 新闻详情5
      { path: 'newsDetails5', component: resolve => require(['@/views/OS/newsDetails5.vue'], resolve) },
      // 新闻详情6
      { path: 'newsDetails6', component: resolve => require(['@/views/OS/newsDetails6.vue'], resolve) },
      // 新闻详情7
      { path: 'newsDetails7', component: resolve => require(['@/views/OS/newsDetails7.vue'], resolve) },
      // 新闻详情8
      { path: 'newsDetails8', component: resolve => require(['@/views/OS/newsDetails8.vue'], resolve) },
      // 新闻详情9
      { path: 'newsDetails9', component: resolve => require(['@/views/OS/newsDetails9.vue'], resolve) },
      // 新闻详情10
      { path: 'newsDetails10', component: resolve => require(['@/views/OS/newsDetails10.vue'], resolve) },
      // 新闻详情11
      { path: 'newsDetails11', component: resolve => require(['@/views/OS/newsDetails11.vue'], resolve) },
      // 新闻详情12
      { path: 'newsDetails12', component: resolve => require(['@/views/OS/newsDetails12.vue'], resolve) },
      // 新闻详情13
      { path: 'newsDetails13', component: resolve => require(['@/views/OS/newsDetails13.vue'], resolve) },
      // 新闻详情14
      { path: 'newsDetails14', component: resolve => require(['@/views/OS/newsDetails14.vue'], resolve) },
      // 新闻详情15
      { path: 'newsDetails15', component: resolve => require(['@/views/OS/newsDetails15.vue'], resolve) },
      // 新闻详情16
      { path: 'newsDetails16', component: resolve => require(['@/views/OS/newsDetails16.vue'], resolve) },
      // 新闻详情17
      { path: 'newsDetails17', component: resolve => require(['@/views/OS/newsDetails17.vue'], resolve) },
      // 新闻详情18
      { path: 'newsDetails18', component: resolve => require(['@/views/OS/newsDetails18.vue'], resolve) },
      // 新闻详情19
      { path: 'newsDetails19', component: resolve => require(['@/views/OS/newsDetails19.vue'], resolve) },
      // 新闻详情20
      { path: 'newsDetails20', component: resolve => require(['@/views/OS/newsDetails20.vue'], resolve) },
      // 新闻详情21
      { path: 'newsDetails21', component: resolve => require(['@/views/OS/newsDetails21.vue'], resolve) },
      // 新闻详情22
      { path: 'newsDetails22', component: resolve => require(['@/views/OS/newsDetails22.vue'], resolve) },
      // 新闻详情23
      { path: 'newsDetails23', component: resolve => require(['@/views/OS/newsDetails23.vue'], resolve) }
    ]
  }
];
export default routes;
