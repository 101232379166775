/*
 * @Author: your name
 * @Date: 2019-12-13 11:35:26
 * @LastEditTime: 2020-12-09 13:58:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-teacherspace-web/src/router/ClassManagement.js
 */

const routes = [
  {
    path: '/desk/joinPromotion', component: resolve => require(['@/views/joinPromotion/index.vue'], resolve)
  },
  {
    path: '/desk/afterSaleService', component: resolve => require(['@/views/afterSaleService/index.vue'], resolve)
  }
];
export default routes;
