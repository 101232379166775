// 首页
const routes = [
  {
    path: '/officialWebsite/companyProfile', component: resolve => require(['@/views/officialWebsite/companyProfile.vue'], resolve)
  },
  {
    path: '/officialWebsite/albumDownload', component: resolve => require(['@/views/officialWebsite/albumDownload.vue'], resolve)
  }
];
export default routes;
